<template>
    <a-card>
        <a-page-header
            :title='"全部线索"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="推送状态">
                        <a-select
                            v-model="form.pushState"
                            placeholder="请选择推送状态"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="1">未推送</a-select-option>
                            <a-select-option key="2">已推送</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col  :span="16">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
            :scroll="{x: 2000}"
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'详情'"
                    @onClickBtn="jumpDetailPage(record.id)"
                ></base-button>
                <!--<base-button-->
                <!--    :type="'link'"-->
                <!--    :title="'修改'"-->
                <!--    @onClickBtn="jumpCarPage(record)"-->
                <!--    v-if="!record.preferCarTrain"-->
                <!--&gt;</base-button>-->
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    fixed: 'left',
                    title: '姓名',
                    dataIndex: 'userName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    fixed: 'left',
                    title: '手机号',
                    dataIndex: 'phone',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    fixed: 'left',
                    title: '购车城市',
                    width: 180,
                    dataIndex: 'purchaseCity',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    fixed: 'left',
                    title: '意向车系',
                    dataIndex: 'preferCarTrain',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '推送状态',
                    customRender:(text, row) => {
                        let txt = <div class="red">待推送</div>
                        return row.pushState == 2 ? '已推送' : txt
                    }
                },
                {
                    align: 'center',
                    title: '线索完整性',
                    customRender:(text, row) => {
                        let txt = <div class="red">缺失</div>
                        return row.isWhole == 2 ? '完整' : txt
                    }
                },
                {
                    align: 'center',
                    title: '分配后广告主',
                    customRender:(text, row) => {
                        return row.aassignAdvertiserName ? row.aassignAdvertiserName : '-'
                    }
                },
                {
                    align: 'center',
                    title: '创建时间',
                    dataIndex: 'platformCtime',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
                {
                    align: 'center',
                    title: '用户填写城市',
                    dataIndex: 'userFillCity',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告平台定义城市',
                    dataIndex: 'platformAppointCity',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '手机归属地城市',
                    dataIndex: 'phoneBelongCity',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '来源应用',
                    dataIndex: 'sourceApp',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告平台',
                    // dataIndex: 'platformId',
                    customRender: (text, row) => {
                        return row.platformId == 1 ? '巨量广告' : row.platformId == 2 ? '磁力广告' : row.platformId == 3 ? '百度信息流' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '站点分类',
                    dataIndex: 'siteType',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '站点标题',
                    dataIndex: 'siteTitle',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 100,
                total: 0
            },
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 100
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = Object.assign(_form, {
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.pphtManage.getAdvertiserClueList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/clueManage/detail`,
                query: {
                    id,
                }
            })
        },
        jumpCarPage(record) {
            this.$router.push({
                path: `/clueManage/carSeries`,
                query: {
                    id: record.id,
                    adGroupName: record.adGroupName,
                    adPlanName: record.adPlanName,
                    siteTitle: record.siteTitle,
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>
